import React from "react"
import { Link } from "gatsby"
import Section from "../../components/Section/Section"
import { useStaticQuery, graphql } from "gatsby"
import "./NextProject.scss"

const NextProject = React.forwardRef(({ currentSlug }, ref) => {
  const { allWpProject } = useStaticQuery(
    graphql`
      query {
        allWpProject(sort: { fields: menuOrder, order: ASC }) {
          nodes {
            title
            uri
            menuOrder
            projectFields {
              suburb
            }
          }
        }
      }
    `
  )

  // console.log(allWpProject.nodes);
  const nextProject = allWpProject.nodes.find(project =>
    project.uri.includes(currentSlug)
  )
  const nextProjectIndex = allWpProject.nodes.indexOf(nextProject)
  const nextProjectNode = allWpProject.nodes[nextProjectIndex + 1]

  if (nextProjectNode) {
    return (
      <Section ref={ref} classes={`next-project`} bgColour="green">
        <div className="container-full">
          <div className="row">
            <div className="column">
              {/* <Link className="btn" to="/projects">Back to Projects</Link> */}
            </div>
          </div>
          <div className="row">
            <div className="column">
              <h3>Next Project</h3>
              <Link to={nextProjectNode.uri}>
                <h2>
                  {nextProjectNode.title}
                  {nextProjectNode.projectFields?.suburb
                    ? `, ${nextProjectNode.projectFields.suburb}`
                    : ""}
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </Section>
    )
  }

  return (
    <Section ref={ref} classes={`next-project`} bgColour="green">
      <div className="container-full">
        <div className="row">
          <div className="column">
            {/* <Link className="btn" to="/projects">Back to Projects</Link> */}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <h3>Next Project</h3>
            <Link to={allWpProject.nodes[0].uri}>
              <h2>
                {allWpProject.nodes[0].title}
                {allWpProject.nodes[0].projectFields.suburb
                  ? `, ${allWpProject.nodes[0].projectFields.suburb}`
                  : ""}
              </h2>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
})

export default NextProject

